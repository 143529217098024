import React, { useState } from 'react';
import { Mail, Phone, Briefcase, Award, Book, Mic, Linkedin, Github, Download, ChevronDown, ChevronUp, Youtube } from 'lucide-react';
import CopyrightFooter from "./footer";

const CVLandingPage = () => {
    const [expandedJob, setExpandedJob] = useState(null);

    const toggleJob = (job) => {
        setExpandedJob(expandedJob === job ? null : job);
    };

    return (
        <div className="max-w-4xl mx-auto p-8 bg-gradient-to-br from-blue-50 to-indigo-100 font-sans">
            <header className="mb-8 bg-white p-6 rounded-lg shadow-lg flex flex-col md:flex-row items-center">
                <div className="mb-4 md:mb-0 md:mr-8 relative">
                    <img
                        src="/headshot.png"
                        alt="Luqman Saeed"
                        className="rounded-full shadow-lg w-48 h-48 object-cover border-4 border-blue-500"
                    />
                    <div className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full">
                        <Award size={24} />
                    </div>
                </div>
                <div className="text-center md:text-left flex-grow">
                    <h1 className="text-4xl font-bold mb-2 text-gray-800">Luqman Saeed</h1>
                    <p className="text-xl text-blue-600 mb-4">Senior Java Developer | Enterprise Architect | Technical Writer & Author | Online Instructor</p>
                    <div className="flex flex-wrap justify-center md:justify-start gap-4 mb-4">
                        <a href="mailto:hi@luqmansaeed.com?subject=Hello%20Luqman&body=Hello%20Luqman%2C%0A%0AI%20hope%20this%20email%20finds%20you%20well." className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300">
                            <Mail className="mr-2" size={18}/> Email
                        </a>
                        <a href="https://www.linkedin.com/in/ghgeek/" className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300">
                            <Linkedin className="mr-2" size={18}/> LinkedIn
                        </a>
                        <a href="https://github.com/pedanticdev" className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-full hover:bg-gray-900 transition duration-300">
                            <Github className="mr-2" size={18}/> GitHub
                        </a>
                        <a href="tel:+233594268813" className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300">
                            <Phone className="mr-2" size={18}/> Call
                        </a>
                        <a href="https://is.gd/oQ38Nd" className="flex items-center bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300">
                            <Download className="mr-2" size={18}/> Download CV
                        </a>
                    </div>
                </div>
            </header>

            <section className="mb-8 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                <h2 className="text-2xl font-semibold mb-4 text-blue-600">About Me</h2>
                <p className="text-gray-700 leading-relaxed">
                    Technical communications specialist and enterprise Java developer with a unique dual expertise that bridges complex technical concepts and effective communication.
                    With 7+ years of experience building scalable backend applications, I excel in translating architectural and engineering concepts into clear content for technical audiences including developers, ML practitioners, and infrastructure specialists.

                </p>
                <br/>
                <p>
                    My background spans both hands-on development with Java, Jakarta EE, Spring, Quarkus and cloud technologies, as well as creating impactful technical documentation, blog posts, and speaking materials.
                    This combination allows me to not only build sophisticated systems but also communicate their value effectively to both technical teams and business stakeholders.
                    I'm passionate about engaging developer communities through my published books, conference presentations, and online courses that have helped over 20,000 developers worldwide.
                </p>
            </section>

            <section className="mb-8 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                <h2 className="text-2xl font-semibold mb-4 flex items-center text-blue-600">
                    <Youtube className="mr-2"/> Featured Talk
                </h2>
                <div className="w-full">
                    <iframe
                        className="w-full h-96 rounded-lg shadow-md"
                        src="https://www.youtube.com/embed/zv9YtQQc67s"
                        title="Building Zero Ops Jakarta EE GenAI RAG Apps"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                <p className="mt-4 text-gray-700 italic">Watch my latest conference talk on Building Zero Ops Jakarta EE GenAI RAG Apps from Eclipse OCX 2024.</p>
            </section>

            <section className="mb-8 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                <h2 className="text-2xl font-semibold mb-4 flex items-center text-blue-600">
                    <Briefcase className="mr-2"/> Work Experience
                </h2>
                <div className="space-y-4">
                    <div className="border-l-4 border-blue-500 pl-4">
                        <h3 className="text-xl font-medium text-gray-800 flex items-center justify-between cursor-pointer"
                            onClick={() => toggleJob('tech11')}>
                            Senior Software Developer - tech11 GmbH
                            {expandedJob === 'tech11' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </h3>
                        <p className="text-gray-600 mb-2">Aug 2019 Present</p>
                        {expandedJob === 'tech11' && (
                            <ul className="list-disc list-inside text-gray-700 mt-2">
                                <li>Key contributor to the development of a cloud-native insurance platform, significantly enhancing overall system efficiency</li>
                                <li>Implemented high-performance RESTful APIs</li>
                                <li>Played a significant role in the successful adoption of CI/CD practices, streamlining the deployment process</li>
                                <li>Contributed substantially to tech11's growth through technical innovations, helping to expand the client base in the EU insuretech market</li>
                            </ul>
                        )}
                    </div>
                    <div className="border-l-4 border-blue-500 pl-4">
                        <h3 className="text-xl font-medium text-gray-800 flex items-center justify-between cursor-pointer"
                            onClick={() => toggleJob('payara')}>
                            Technical Writer - Payara Services Ltd.
                            {expandedJob === 'payara' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </h3>
                        <p className="text-gray-600 mb-2">Nov 2022 - Present</p>
                        {expandedJob === 'payara' && (
                            <ul className="list-disc list-inside text-gray-700 mt-2">
                                <li>Authored comprehensive documentation for Jakarta EE runtime, improving user adoption</li>
                                <li>Created accessible tutorials that increased community engagement</li>
                            </ul>
                        )}
                    </div>
                    <div className="border-l-4 border-blue-500 pl-4">
                        <h3 className="text-xl font-medium text-gray-800 flex items-center justify-between cursor-pointer"
                            onClick={() => toggleJob('udemy')}>
                            Independent Online Instructor - Udemy
                            {expandedJob === 'udemy' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </h3>
                        <p className="text-gray-600 mb-2">May 2017 - Present</p>
                        {expandedJob === 'udemy' && (
                            <ul className="list-disc list-inside text-gray-700 mt-2">
                                <li>Developed "Java Enterprise Edition (Jakarta EE) for Beginners" course</li>
                                <li>Educated over <a href="https://www.udemy.com/user/salmankhan/" className="text-blue-600 hover:text-blue-800 underline">
                                    20,000 developers</a>, maintaining a 4.6/5 star rating</li>
                            </ul>
                        )}
                    </div>
                </div>
            </section>

            <div className="grid md:grid-cols-2 gap-8">
                <section className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                    <h2 className="text-2xl font-semibold mb-4 flex items-center text-blue-600">
                        <Book className="mr-2"/> Publications
                    </h2>
                    <ul className="space-y-2">
                        <li>
                            <a href="https://link.springer.com/book/10.1007/978-1-4842-8900-6"
                               className="text-blue-600 hover:text-blue-800 underline">
                                Pro Cloud Native Java EE Apps, Apress (2022)
                            </a>
                        </li>
                        <li>
                            <a href="https://www.amazon.com/Jakarta-Dependency-Injection-Java-Developers-ebook/dp/B07S7R9KG4?ref_=ast_author_dp"
                               className="text-blue-600 hover:text-blue-800 underline">
                                Introducing Jakarta EE CDI, Apress (2020)
                            </a>
                        </li>
                    </ul>
                </section>

                <section className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                    <h2 className="text-2xl font-semibold mb-4 flex items-center text-blue-600">
                        <Mic className="mr-2"/> Conference Speakership
                    </h2>
                    <ul className="list-disc list-inside text-gray-700">
                        <li>JavaLand (2024) - Scaling Java Applications in the Cloud</li>
                        <li><a href="https://youtu.be/zv9YtQQc67s?si=y9KJ3CJ6OS_R6ink"
                               className="text-blue-600 hover:text-blue-800 underline" rel="noreferrer" target="_blank">Eclipse
                            OCX (2024)</a> - Building Zero Ops Jakarta EE GenAI RAG Apps
                        </li>
                        <li>JakartaOne (2023) - Best Practices for Jakarta EE Development</li>
                    </ul>
                </section>
            </div>

            <section className="mt-8 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                <h2 className="text-2xl font-semibold mb-4 flex items-center text-blue-600">
                    <Award className="mr-2"/> Technical Skills
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    <div>
                        <h3 className="font-semibold text-gray-800">Languages</h3>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Java (Advanced)</li>
                            <li>SQL (Proficient)</li>
                            <li>JavaScript (Intermediate)</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="font-semibold text-gray-800">Frameworks</h3>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Spring Boot (Expert)</li>
                            <li>Jakarta EE (Advanced)</li>
                            <li>Quarkus (Proficient)</li>
                            <li>React (Intermediate)</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="font-semibold text-gray-800">Tools & Technologies</h3>
                        <ul className="list-disc list-inside text-gray-700">
                            <li>Docker & Kubernetes</li>
                            <li>CI/CD (Jenkins, GitLab)</li>
                            <li>AWS & Azure Cloud</li>
                            <li>Microservices Architecture</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="mt-8 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
                <h2 className="text-2xl font-semibold mb-4 flex items-center text-blue-600">
                    <Book className="mr-2"/> Education
                </h2>
                <div className="space-y-4">
                    <div className="border-l-4 border-blue-500 pl-4">
                        <h3 className="text-xl font-medium text-gray-800">MBA in Finance</h3>
                        <p className="text-gray-600">Advanced studies in financial management and business strategy</p>
                    </div>
                    <div className="border-l-4 border-blue-500 pl-4">
                        <h3 className="text-xl font-medium text-gray-800">BSc in Banking & Finance</h3>
                        <p className="text-gray-600">Foundation in financial systems and economic principles</p>
                    </div>
                    <div className="border-l-4 border-blue-500 pl-4">
                        <h3 className="text-xl font-medium text-gray-800">Professional Development</h3>
                        <p className="text-gray-600">Continuous learning through certifications and courses in Java, cloud technologies, and software architecture</p>
                    </div>
                </div>
            </section>
            <footer className="mt-8 text-center">
                <CopyrightFooter/>
                <p className="mt-2 text-blue-600 font-semibold"><a href="mailto:hi@luqmansaeed.com"
                                                                   className="mt-2 text-blue-600 font-semibold hover:underline inline-block">Let's
                    connect and create something amazing together!</a></p>
            </footer>
        </div>
    );
};

export default CVLandingPage;