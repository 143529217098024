


import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams, Link } from 'react-router-dom';
import { Share2, MessageCircle, Edit2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark, materialLight, dracula, atomDark, oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy, Check } from 'lucide-react';
import { db, storage } from './firebase-config';
import { useAuth } from './AuthContext';
import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    doc,
    updateDoc,
    orderBy,
    query,
    serverTimestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// Helper function for URL-friendly slugs remains the same
const createSlug = (title) => {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')
        .replace(/\s+/g, '-')
        .trim();
};
const CodeBlock = ({ language, children }) => {
    const [copied, setCopied] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState('materialDark');

    const themes = {
        materialDark,
        materialLight,
        dracula,
        atomDark,
        oneDark
    };

    const copyCode = () => {
        navigator.clipboard.writeText(children);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="relative group">
            <div className="absolute right-2 top-2 flex items-center gap-2">
                <select
                    value={selectedTheme}
                    onChange={(e) => setSelectedTheme(e.target.value)}
                    className="bg-gray-700 text-white text-sm rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                    <option value="materialDark">Material Dark</option>
                    <option value="materialLight">Material Light</option>
                    <option value="dracula">Dracula</option>
                    <option value="atomDark">Atom Dark</option>
                    <option value="oneDark">One Dark</option>
                </select>
                <button
                    onClick={copyCode}
                    className="bg-gray-700 text-white p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity"
                    title="Copy code"
                >
                    {copied ? <Check size={14} /> : <Copy size={14} />}
                </button>
            </div>
            <SyntaxHighlighter
                style={themes[selectedTheme]}
                language={language || 'text'}
                PreTag="div"
                showLineNumbers={true}
                wrapLines={true}
                className="rounded-lg my-4 !mt-0"
            >
                {children}
            </SyntaxHighlighter>
        </div>
    );
};

// ProtectedRoute Component remains the same
const ProtectedRoute = ({ children }) => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !user) {
            navigate('/login');
        }
    }, [user, loading, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return user ? children : null;
};

// HomePage Component - Modified to render markdown preview
const HomePage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const postsQuery = query(
                    collection(db, 'posts'),
                    orderBy('createdAt', 'desc')
                );
                const querySnapshot = await getDocs(postsQuery);
                const fetchedPosts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    date: doc.data().createdAt?.toDate().toLocaleDateString() || 'No date'
                }));
                setPosts(fetchedPosts);
            } catch (err) {
                setError('Failed to load posts');
                console.error('Error fetching posts:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchPosts();
    }, []);

    if (loading) return <div className="text-center py-8">Loading posts...</div>;
    if (error) return <div className="text-red-500 text-center py-8">{error}</div>;

    return (
        <>
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-2xl font-bold text-gray-800">Technical Blog</h1>
                {user && (
                    <Link
                        to="/blog/new"
                        className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
                    >
                        <Edit2 size={18} className="mr-2" aria-hidden="true" /> New Post
                    </Link>
                )}
            </div>
            <div className="grid md:grid-cols-2 gap-8">
                {posts.map(post => (
                    <article
                        key={post.id}
                        className="bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer"
                        onClick={() => navigate(`/blog/post/${createSlug(post.title)}-${post.id}`)}
                    >
                        {/*<img*/}
                        {/*    src={post.image || '/api/placeholder/400/300'}*/}
                        {/*    alt={post.title}*/}
                        {/*    className="w-full h-48 object-cover rounded-t-lg"*/}
                        {/*/>*/}
                        <div className="p-6">
                            <h2 className="text-xl font-semibold mb-3 text-gray-800">{post.title}</h2>
                            <div className="text-gray-600 mb-4 line-clamp-3">
                                <ReactMarkdown>{post.content}</ReactMarkdown>
                            </div>
                            <time className="text-sm text-gray-500">{post.date}</time>
                        </div>
                    </article>
                ))}
            </div>
        </>
    );
};

// PostPage Wrapper Component
const PostPageWrapper = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postId = slug.split('-').pop();
                const postDoc = await getDoc(doc(db, 'posts', postId));
                if (!postDoc.exists()) {
                    throw new Error('Post not found');
                }
                setPost({
                    id: postDoc.id,
                    ...postDoc.data(),
                    date: postDoc.data().createdAt?.toDate().toLocaleDateString() || 'No date'
                });
            } catch (err) {
                setError('Failed to load post');
                console.error('Error fetching post:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
    }, [slug]);

    if (loading) {
        return <div className="text-center py-8">Loading post...</div>;
    }
    if (error || !post) {
        navigate('/blog');
        return null;
    }
    return <PostPage post={post} />;
};

// PostPage Component - Modified to render markdown
const PostPage = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [submittingComment, setSubmittingComment] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postId = slug.split('-').pop();
                console.log(postId)
                const postDoc = await getDoc(doc(db, 'posts', postId));
                console.log(postDoc);
                if (!postDoc.exists()) {
                    throw new Error('Post not found');
                }

                const postData = postDoc.data();
                console.log(postData);
                // Fetch content from Storage
                const response = await fetch(postData.contentUrl, {
                    headers: {
                        'Accept': 'text/markdown',
                    },
                    mode: 'cors'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch content');
                }

                const contentText = await response.text();
                console.log(contentText);

                setPost({
                    id: postDoc.id,
                    ...postData,
                    date: postData.createdAt?.toDate().toLocaleDateString()
                });
                setContent(contentText);
                setComments(postData.comments || []);
            } catch (err) {
                setError('Failed to load post');
                console.error('Error fetching post:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
    }, [slug]);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!newComment.trim() || submittingComment) return;

        setSubmittingComment(true);
        try {
            const comment = {
                content: newComment,
                createdAt: serverTimestamp()
            };

            const postRef = doc(db, 'posts', post.id);
            await updateDoc(postRef, {
                comments: [...comments, comment]
            });

            setComments([...comments, {
                ...comment,
                createdAt: new Date()
            }]);
            setNewComment('');
        } catch (err) {
            console.error('Error adding comment:', err);
        } finally {
            setSubmittingComment(false);
        }
    };

    if (loading) return <div className="text-center py-8">Loading post...</div>;
    if (error) return <div className="text-red-500 text-center py-8">{error}</div>;
    if (!post) {
        navigate('/blog');
        return null;
    }
    return (
        <div>
            <button
                onClick={() => navigate('/blog')}
                className="mb-6 text-blue-600 hover:text-blue-800 font-medium flex items-center"
            >
                ← Back to Posts
            </button>
            <article className="bg-white rounded-lg shadow-lg p-8">

                <h1 className="text-5xl font-bold mb-4 text-gray-800">{post.title}</h1>
                <div className="flex items-center justify-between mb-8 text-gray-500">
                    <time>{post.date}</time>
                    <span>{post.readingTime} min read</span>
                </div>
                <div className="prose max-w-none mb-8 text-gray-700">
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-6 mb-4" {...props} />,
                            h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-5 mb-3" {...props} />,
                            h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-4 mb-2" {...props} />,
                            p: ({node, ...props}) => <p className="my-4" {...props} />,
                            ul: ({node, ...props}) => <ul className="list-disc pl-6 my-4" {...props} />,
                            ol: ({node, ...props}) => <ol className="list-decimal pl-6 my-4" {...props} />,
                            li: ({node, ...props}) => <li className="mb-1" {...props} />,
                            table: ({node, ...props}) => (
                                <div className="overflow-x-auto my-6">
                                    <table className="min-w-full border-collapse border border-gray-300 table-auto" {...props} />
                                </div>
                            ),
                            thead: ({node, ...props}) => <thead className="bg-gray-100" {...props} />,
                            th: ({node, ...props}) => (
                                <th className="border border-gray-300 px-4 py-2 text-left font-bold bg-gray-50" {...props} />
                            ),
                            td: ({node, ...props}) => (
                                <td className="border border-gray-300 px-4 py-2 align-top" {...props} />
                            ),
                            blockquote: ({node, ...props}) => (
                                <blockquote className="border-l-4 border-gray-300 pl-4 my-4 italic" {...props} />
                            ),
                            code: ({node, inline, className, children, ...props}) => {
                                const match = /language-(\w+)/.exec(className || '');
                                const language = match ? match[1] : '';
                                return !inline ? (
                                    <CodeBlock language={language}>
                                        {String(children).replace(/\n$/, '')}
                                    </CodeBlock>
                                ) : (
                                    <code className="bg-gray-800 text-white px-1 rounded" {...props}>
                                        {children}
                                    </code>
                                )
                            },
                        }}
                    >
                        {content}
                    </ReactMarkdown>
                </div>
                <div className="flex space-x-4 mb-8 border-t pt-6">
                    <button className="flex items-center space-x-2 text-blue-600 hover:text-blue-800">
                        <Share2 size={20} aria-hidden="true" />
                        <span>Share</span>
                    </button>
                    <button className="flex items-center space-x-2 text-blue-600 hover:text-blue-800">
                        <MessageCircle size={20} aria-hidden="true" />
                        <span>Comment</span>
                    </button>
                </div>
                <section className="border-t pt-8">
                    <h2 className="text-2xl font-semibold mb-6 text-gray-800">Comments</h2>
                    <form onSubmit={handleCommentSubmit} className="mb-8">
                        <textarea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add a comment..."
                            className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:border-blue-300 outline-none"
                            rows="3"
                            disabled={submittingComment}
                        />
                        <button
                            type="submit"
                            className={`mt-2 px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 ${
                                submittingComment ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            disabled={submittingComment}
                        >
                            {submittingComment ? 'Posting...' : 'Post Comment'}
                        </button>
                    </form>
                    <div className="space-y-6">
                        {comments.map((comment, index) => (
                            <div key={index} className="bg-gray-50 p-6 rounded-lg">
                                <p className="mb-2 text-gray-700">{comment.content}</p>
                                <time className="text-sm text-gray-500">
                                    {comment.createdAt instanceof Date
                                        ? comment.createdAt.toLocaleDateString()
                                        : 'Just now'}
                                </time>
                            </div>
                        ))}
                    </div>
                </section>
            </article>
        </div>
    );
};

// Main Blog Component
const BlogApp = () => {
    return (
        <div className="max-w-4xl mx-auto px-8 py-8">
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/post/:slug" element={<PostPageWrapper />} />
                <Route
                    path="/new"
                    element={
                        <ProtectedRoute>
                            <Editor />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </div>
    );
};

// Editor Component - Modified to show markdown preview
const Editor = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [preview, setPreview] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submit button clicked');

        if (!title.trim() || !content.trim()) {
            setError('Title and content are required');
            console.log("Title and content are required");
            return;
        }

        if (!user) {
            setError('You must be logged in to publish');
            return;
        }

        setSubmitting(true);
        setError(null);

        try {
            console.log('Creating storage reference');

            // Create a unique file name for the content
            const fileName = `posts/${Date.now()}-${title.toLowerCase().replace(/\s+/g, '-')}.md`;
            const storageRef = ref(storage, fileName);

            console.log('Uploading content to storage');

            // Upload content to Storage
            const contentBlob = new Blob([content], { type: 'text/markdown' });
            const uploadResult = await uploadBytes(storageRef, contentBlob);

            console.log('Getting download URL');
            const contentUrl = await getDownloadURL(uploadResult.ref);

            console.log('Saving to Firestore');

            // Save post metadata to Firestore (removed image field)
            const postData = {
                title,
                contentUrl,
                fileName,
                author: user.uid,
                createdAt: serverTimestamp(),
                summary: content.substring(0, 150) + '...',
                readingTime: Math.ceil(content.split(' ').length / 200)
            };

            const docRef = await addDoc(collection(db, 'posts'), postData);
            console.log('Post created successfully:', docRef.id);

            // Navigate to the new post
            navigate(`/blog/post/${title.toLowerCase().replace(/\s+/g, '-')}-${docRef.id}`);
        } catch (err) {
            console.error('Error creating post:', err);
            setError(`Failed to publish post: ${err.message}`);
            setSubmitting(false);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="flex justify-between items-center mb-8">
                <h2 className="text-2xl font-semibold text-gray-800">Create New Post</h2>
                <button
                    onClick={() => setPreview(!preview)}
                    className="px-4 py-2 border rounded-full hover:bg-gray-50 text-gray-700"
                >
                    {preview ? 'Edit' : 'Preview'}
                </button>
            </div>
            {!preview ? (
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Post title"
                        className="w-full p-4 text-xl border rounded-lg mb-6 focus:ring-2 focus:ring-blue-200 focus:border-blue-300 outline-none"
                        disabled={submitting}
                    />
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Write your post content (supports markdown)..."
                        className="w-full p-4 border rounded-lg h-96 mb-6 focus:ring-2 focus:ring-blue-200 focus:border-blue-300 outline-none font-mono"
                        disabled={submitting}
                    />
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className={`px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 ${
                                submitting ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            disabled={submitting}
                        >
                            {submitting ? 'Publishing...' : 'Publish Post'}
                        </button>
                    </div>
                </form>
            ) : (
                <div className="prose max-w-none bg-gray-50 p-6 rounded-lg">
                    <h1 className="text-3xl font-bold mb-4">{title}</h1>
                    <div className="markdown-preview">
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                                h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-6 mb-4" {...props} />,
                                h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-5 mb-3" {...props} />,
                                h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-4 mb-2" {...props} />,
                                p: ({node, ...props}) => <p className="my-4" {...props} />,
                                ul: ({node, ...props}) => <ul className="list-disc pl-6 my-4" {...props} />,
                                ol: ({node, ...props}) => <ol className="list-decimal pl-6 my-4" {...props} />,
                                li: ({node, ...props}) => <li className="mb-1" {...props} />,
                                table: ({node, ...props}) => (
                                    <div className="overflow-x-auto my-6">
                                        <table className="min-w-full border-collapse border border-gray-300 table-auto" {...props} />
                                    </div>
                                ),
                                thead: ({node, ...props}) => <thead className="bg-gray-100" {...props} />,
                                th: ({node, ...props}) => (
                                    <th className="border border-gray-300 px-4 py-2 text-left font-bold bg-gray-50" {...props} />
                                ),
                                td: ({node, ...props}) => (
                                    <td className="border border-gray-300 px-4 py-2 align-top" {...props} />
                                ),
                                blockquote: ({node, ...props}) => (
                                    <blockquote className="border-l-4 border-gray-300 pl-4 my-4 italic" {...props} />
                                ),
                                code: ({node, inline, className, children, ...props}) => {
                                    const match = /language-(\w+)/.exec(className || '');
                                    const language = match ? match[1] : '';
                                    return !inline ? (
                                        <CodeBlock language={language}>
                                            {String(children).replace(/\n$/, '')}
                                        </CodeBlock>
                                    ) : (
                                        <code className="bg-gray-800 text-white px-1 rounded" {...props}>
                                            {children}
                                        </code>
                                    )
                                },
                            }}
                        >
                            {content}
                        </ReactMarkdown>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogApp;